"use client";

import Image from "next/image";
import Script from "next/script";
import { createRef, useEffect, useId, useState } from "react";
import { SeedWave } from "~components/ui/seed-wave";
import {
  type PageContext,
  buildImageLoader,
  getImageDimensions,
} from "~lib/storyblok";
import { cn } from "~utils";
import "../../../app/hubspot.css";
import { RichText } from "../rich-text";
import type { HubspotFormSectionBlok } from "./types";

type HubspotFormSectionProps = {
  blok: HubspotFormSectionBlok;
} & PageContext;

declare global {
  interface Window {
    hbspt: {
      forms: {
        create(opts: {
          region: string;
          portalId: string;
          formId: string;
          target: Element | string;
        }): void;
      };
    };
  }
}

export function HubspotFormSection({
  blok,
  ...context
}: HubspotFormSectionProps) {
  const id = useId();

  // Build a prefixed, cleaner id for the html element.
  const targetId = `hbspot-${id.replace(/:/g, "")}`;

  const [isLibLoaded, setIsLibLoaded] = useState(false);
  const embedRef = createRef<HTMLDivElement>();

  useEffect(() => {
    if (!isLibLoaded || !targetId) return;

    window.hbspt.forms.create({
      region: blok.hubspotRegion,
      portalId: blok.hubspotPortalId,
      formId: blok.hubspotFormId,
      target: `#${targetId}`,
    });
  }, [targetId, blok, isLibLoaded]);

  return (
    <>
      <Script
        type="text/javascript"
        src="//js-eu1.hsforms.net/forms/embed/v2.js"
        onLoad={() => setIsLibLoaded(true)}
      />

      <section
        id={blok.sectionId}
        data-section-type="hubspot-form"
        className={cn(
          "section relative overflow-hidden text-content-text",
          blok.theme || "section-sky",
          blok.variant === "split" && "bg-surface/contrast py-[0px]",
        )}
      >
        {blok.pattern && (
          <SeedWave
            className="section-item-full absolute top-[0] h-full w-full bg-decor/accent"
            seedPatternColor={blok.seedPatternColor}
          />
        )}

        <div
          className={cn(
            "relative grid",
            blok.variant === "split" && "section-item-full lg:grid-cols-2",
          )}
        >
          <div
            className={cn(
              "flex w-full flex-col place-items-center gap-md justify-self-center bg-surface/200 px-sm md:gap-lg-xl md:px-lg-xl",
              blok.variant === "default"
                ? "max-w-[34em] rounded-lg bg-surface/contrast py-xl-2xl pb-lg-xl"
                : "px-[var(--section-px)] py-[var(--section-py)]",
            )}
          >
            <div className="flex flex-col gap-sm">
              {blok.leading && (
                <h2 className="-mb-2xs t-prosi-lg max-w-sub text-pretty text-content-heading leading-tight">
                  {blok.leading}
                </h2>
              )}
              {blok.title && (
                <h1 className="t-strong-3xl max-w-title text-balance text-content-heading">
                  {blok.title}
                </h1>
              )}

              {blok.text && (
                <RichText
                  className="t-default-md max-w-text/sm text-pretty leading-normal"
                  data={blok.text}
                  {...context}
                />
              )}
            </div>

            <div ref={embedRef} id={targetId} className={cn("w-full")} />
          </div>

          {blok.backgroundImage?.filename && (
            <Image
              sizes="(max-width: 1024px) 100vw, 50vw"
              src={blok.backgroundImage.filename}
              alt={blok.backgroundImage.alt}
              {...getImageDimensions(blok.backgroundImage)}
              loader={buildImageLoader({ focus: blok.backgroundImage.focus })}
              className={cn("h-full w-full object-cover")}
              quality={+blok?.image_quality || 100}
            />
          )}
        </div>
      </section>
    </>
  );
}
